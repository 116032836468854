



















import Vue from 'vue';
import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';
import AddressModel from '../../../../../services/viewModel/resource/MemberBusinessAddressResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    subscriptionAddress: new AddressModel(),
  }),
  computed: {
    subscriptionDisplay(): any {
      const [subscription] = this.innerValue.orderBasket?.items ?? [];
      return subscription?.articleDisplay!;
    },
    innerValue: {
      get(): CreateSubscriptionContractRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateSubscriptionContractRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    const response = await this.$service.api.me.getMe();
    this.innerValue.billingContact.address.eMail = response.email;
  },
});
